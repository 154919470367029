import React, { useEffect, useState, useRef } from "react"
import "../static/style/globalCss/platform-page.scss"
import "../static/style/globalCss/style.scss"
import { Link, graphql, useStaticQuery } from "gatsby"
import RequestDemo from "../components/requestDemo"
import Parser from "html-react-parser"
import BannerHeader from "../components/bannerHeader"
import Underline from "../components/underline"
import { pryzmaiData } from "../services/helperFunctions"
import "../static/style/pageStyle/empower-pryzm.scss"
import BlackBtn from "../components/black-btn"
import VideoComponent from "../components/videoComponent"
import pryzmVideo from "../static/images/video/Pryzm-video.mp4"
import { Helmet } from "react-helmet"
import FinancialServiceSlider from "../components/financial-service-slider"
import Slider from "react-slick"
import Loadable from "@loadable/component"
import GetStartedModal from "../components/getStaredModal"
const LoadableCardsMobile = Loadable(() => import("../components/cards-mobile"))
const LoadableCards = Loadable(() => import("../components/cards"))

const EmpPryzm = props => {
  const data = useStaticQuery(
    graphql`
      {
        pryzmai {
          pryzmai {
            journey {
              attributes {
                cmpName
                imageUrl
                heading
                subheading
                dynamicCmp {
                  heading
                  paragraph
                  imageUrl
                }
              }
            }
          }
        }
      }
    `
  )
  useEffect(() => {
    if (props?.location?.state) {
      const id = Object.values(props?.location?.state)
        .filter(
          (value, index) =>
            typeof value === "string" &&
            index !== Object.keys(props?.location?.state).length - 1
        )
        .join("")
      if (id) {
        const partnersLocation = document.getElementById(id)
        if (partnersLocation) {
          partnersLocation.scrollIntoView({ behavior: "smooth" })
        }
      }
    }
  }, [props?.location?.state])
  const [isNavBarOpen, setIsNavBarOpen] = useState(true);
 
 

  const {
    banner,
    section1,
    section2,
    section3,
    section4,
    section5,
    section6,
    section7,
    section8,
    testimonialSlider,
  } = pryzmaiData(data) 
    const [isOpen, setIsOpen] = useState(false)
  // console.log(isOpen,'navbar')
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const handleIsOpenModal = (isModalOpen)=>{
    setIsModalOpen(!isModalOpen);
    setIsOpen(!isOpen)
  }
  return (
    <>
      <div
        className={`platform-v3 emPower-Pryzm  ${
          isNavBarOpen ? "" : "no-scroll"
        }`}
      >
        <div className="page-content">
          {/* banner  */}
          <div className="banner">
            <div className="page-wrapper">
              <div className="main-container">
                <BannerHeader header={banner.heading} line={"two-line"} headingType={"bannerTitle"}/>
                <div className="sub-heading">
                  <p>{Parser(banner.subheading)}</p>
                </div>
                <div className="btn-area">
                   <GetStartedModal content={"Know More"} isOpen={isOpen} setIsOpen={setIsOpen} isModalOpen={isModalOpen} handleIsOpenModal={handleIsOpenModal} />
                </div>
              </div>
              <div className="banner-image">
                <img src={`${process.env.STRAPI_URL + banner.imageUrl}`}></img>
              </div>
            </div>
          </div>
          {/* section-01  Data-break is challenge */}
          <div className="section-01">
            <div className="page-wrapper">
              <div className="main-container">
                <BannerHeader header={section1.heading} line={"single-line"} />
                <div className="box-flex">
                  {section1.subheading.split(",").map(item => (
                    <div className="flex-item">
                      <p>{item}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            
          </div>
          {/* section-02 Ahead of every data  */}
          <div className="section-02">
            <div className="page-wrapper">
              <div className="main-container">
                <div className="sub-heading">
                  <p>{section2.subheading}</p>
                </div>

                <BannerHeader header={section2.heading} line={"single-line"} />
              </div>
            </div>
          </div>
              <div className="banner-wrapper">
                <div className="page-wrapper">
                  <div className="page-banner">
                    <div className="video-area">
                      <div className="video-inner">
                        <h3>Pryzm Introduction</h3>
                        <div className="image-container-header">
                          <VideoComponent
                            video={pryzmVideo}
                            poster={section2.imageUrl}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          <div className="section-02">
            <div className="page-wrapper">    
              <div className="btn-area">
                   <GetStartedModal content={"Book A Demo"} isOpen={isOpen} setIsOpen={setIsOpen} isModalOpen={isModalOpen} handleIsOpenModal={handleIsOpenModal} />
              </div>
              <div className="data-image">
                <img
                  src={`${process.env.STRAPI_URL + section2.dynamicCmp[0].imageUrl}`}/>
              </div>
            </div>
          </div>
          <div className="section-03">
            <div className="page-wrapper">
              <div className="main-container">
                <div className="single-content">
                  <h2>{Parser(section3.heading)}</h2>
                </div>
                <div className="double-content">
                  <div className="double-content-first">
                    <h2>{section3.subheading}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Underline />
          <div className="section-04">
            <div className="page-wrapper ">
              <div className="main-container">
                <BannerHeader header={section4.heading} line={"two-line"} />
                <div className="sub-heading">
                  <p>{Parser(section4.subheading)}</p>
                </div>
                <div className="btn-area">
                  <Link to="/benefits" name="get-startd">
                    {" "}
                    <BlackBtn text={"Discover Benefits"} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Underline />
          <div className="section-05">
            <div className="page-wrapper ">
              <div className="main-container">
                <BannerHeader header={section5.heading} line={"single-line"} />
                <div className="sub-heading">
                  <p>{Parser(section5.subheading)}</p>
                </div>
                <div className="btn-area">
                  <Link to="/features" name="get-startd">
                    {" "}
                    <BlackBtn text={"Explore Features"} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Underline />
          <div className="section-06">
            <div className="page-wrapper ">
              <div className="main-container">
                <RequestDemo content={section6}/>
              </div>
            </div>
          </div>
          <Underline />
          {/* powered by LUMIQ  */}
          <div className="section-07">
            <div className="page-wrapper ">
              <div className="main-container">
                {/* <RequestDemo /> */}
                <BannerHeader header={section7.heading} line={"single-line"} />
                <div className="sub-heading">
                  <p className="paragraph">{Parser(section7.subheading)}</p>
                </div>
              </div>
            </div>
          </div>
          <Underline />

          <FinancialServiceSlider />
          <Underline />
          <div className="section-08">
            <div className="page-wrapper">
              <div className="main-container" id="customerSuccess">
                <BannerHeader header={section8.heading} line={"single-line"} />
                <div className="card-container">
                  <div className="testimonialDesktop" aria-label="slider">
                    <Slider {...testimonialSlider}>
                      {section8.dynamicCmp.map((item, i) => (
                        <LoadableCards
                          key={i}
                          cardData={item.paragraph}
                          cardClient={item.heading}
                        />
                      ))}
                    </Slider>
                  </div>
                </div>
                <div className="card-container-mobile ">
                  <div className="testimonialDesktop">
                    {section8.dynamicCmp.map((item, i) => (
                      <LoadableCardsMobile
                        key={i}
                        cardData={item.paragraph}
                        cardClient={item.heading}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmpPryzm
