import React, { useEffect, useState, useRef } from "react"

import '../static/style/componentStyle/requestDemo.scss';
import BannerHeader from "./bannerHeader";
import Parser from "html-react-parser"
import GetStartedModal from "./getStaredModal";



const RequestDemo = (props) => {
  // const data = useStaticQuery(
  //   graphql`
  //     {
  //       allCmpGrps {
  //         nodes {
  //           cmpGrps {
  //             journey {
  //               attributes {
  //                 cmpName
  //                 dynamicCmp {
  //                   buttonName
  //                   cardDes
  //                   cardName
  //                   cmpName
  //                   header
  //                   paragraph
  //                   subHeader
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   `
  // )
  // const { ref: request, inView: requestRef } = useInView({ triggerOnce: true })

  // const [requestDemo, setRequestDemo] = useState(false)

  // useEffect(() => {
  //   if (requestRef) {
  //     setRequestDemo(true)
  //   }
  // },[requestRef])
  // let requestDemoObj = data?.allCmpGrps?.nodes[0]?.cmpGrps?.journey[1]?.attributes?.dynamicCmp[0];
  // console.log(requestDemoObj,'datadata');

  const [isOpen, setIsOpen] = useState(false)
  // console.log(isOpen,'navbar')
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const handleIsOpenModal = (isModalOpen)=>{
    setIsModalOpen(!isModalOpen);
    setIsOpen(!isOpen)
  }
  return (
    <div className="request-demo">
      <div className="section-inner">
        <div className="left-content">
         <BannerHeader
          header={"Request a Demo"}
            line={"single-line"}
          />
          
          <h3 className="">
            {Parser("Take a platform tour to explore how it can solve your data challenges")}
          </h3>
        </div>
        <div className="right-content">
          <div className='btn-area'>
            {/* <CircleBtn BtnText='Get Started' /> */}
            <GetStartedModal content={"Get Started"} isOpen={isOpen} setIsOpen={setIsOpen} isModalOpen={isModalOpen} handleIsOpenModal={handleIsOpenModal} />
           
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestDemo
