export const FetchGetStarted = data => {
  const countries = [
    { label: "Afghanistan", value: "af" },
    { label: "Åland Islands", value: "ax" },
    { label: "Albania", value: "al" },
    { label: "Algeria", value: "dz" },
    { label: "American Samoa", value: "as" },
    { label: "Andorra", value: "ad" },
    { label: "Angola", value: "ao" },
    { label: "Anguilla", value: "ai" },
    { label: "Antarctica", value: "aq" },
    { label: "Antigua and Barbuda", value: "ag" },
    { label: "Argentina", value: "ar" },
    { label: "Armenia", value: "am" },
    { label: "Aruba", value: "aw" },
    { label: "Australia", value: "au" },
    { label: "Austria", value: "at" },
    { label: "Azerbaijan", value: "az" },
    { label: "Bahamas", value: "bs" },
    { label: "Bahrain", value: "bh" },
    { label: "Bangladesh", value: "bd" },
    { label: "Barbados", value: "bb" },
    { label: "Belarus", value: "by" },
    { label: "Belgium", value: "be" },
    { label: "Belize", value: "bz" },
    { label: "Benin", value: "bj" },
    { label: "Bermuda", value: "bm" },
    { label: "Bhutan", value: "bt" },
    { label: "Bolivia", value: "bo" },
    { label: "Bosnia and Herzegovina", value: "ba" },
    { label: "Botswana", value: "bw" },
    { label: "Bouvet Island", value: "bv" },
    { label: "Brazil", value: "br" },
    // { label: "British Indian Ocean Territory", value: "io" },
    { label: "Brunei Darussalam", value: "bn" },
    { label: "Bulgaria", value: "bg" },
    { label: "Burkina Faso", value: "bf" },
    { label: "Burundi", value: "bi" },
    { label: "Cambodia", value: "kh" },
    { label: "Cameroon", value: "cm" },
    { label: "Canada", value: "ca" },
    { label: "Cape Verde", value: "cv" },
    { label: "Cayman Islands", value: "ky" },
    { label: "Central African Republic", value: "cf" },
    { label: "Chad", value: "td" },
    { label: "Chile", value: "cl" },
    { label: "China", value: "cn" },
    { label: "Christmas Island", value: "cx" },
    { label: "Cocos (Keeling) Islands", value: "cc" },
    { label: "Colombia", value: "co" },
    { label: "Comoros", value: "km" },
    { label: "Congo", value: "cg" },
    { label: "Congo, The Democratic Republic of the", value: "cd" },
    { label: "Cook Islands", value: "ck" },
    { label: "Costa Rica", value: "cr" },
    { label: "Cote D'Ivoire", value: "ci" },
    { label: "Croatia", value: "hr" },
    { label: "Cuba", value: "cu" },
    { label: "Cyprus", value: "cy" },
    { label: "Czech Republic", value: "cz" },
    { label: "Denmark", value: "dk" },
    { label: "Djibouti", value: "dj" },
    { label: "Dominica", value: "dm" },
    { label: "Dominican Republic", value: "do" },
    { label: "Ecuador", value: "ec" },
    { label: "Egypt", value: "eg" },
    { label: "El Salvador", value: "sv" },
    { label: "Equatorial Guinea", value: "gq" },
    { label: "Eritrea", value: "er" },
    { label: "Estonia", value: "ee" },
    { label: "Ethiopia", value: "et" },
    { label: "Falkland Islands (Malvinas)", value: "fk" },
    { label: "Faroe Islands", value: "fo" },
    { label: "Fiji", value: "fj" },
    { label: "Finland", value: "fi" },
    { label: "France", value: "fr" },
    { label: "French Guiana", value: "gf" },
    { label: "French Polynesia", value: "pf" },
    { label: "French Southern Territories", value: "tf" },
    { label: "Gabon", value: "ga" },
    { label: "Gambia", value: "gm" },
    { label: "Georgia", value: "ge" },
    { label: "Germany", value: "de" },
    { label: "Ghana", value: "gh" },
    { label: "Gibraltar", value: "gi" },
    { label: "Greece", value: "gr" },
    { label: "Greenland", value: "gl" },
    { label: "Grenada", value: "gd" },
    { label: "Guadeloupe", value: "gp" },
    { label: "Guam", value: "gu" },
    { label: "Guatemala", value: "gt" },
    { label: "Guernsey", value: "gg" },
    { label: "Guinea", value: "gn" },
    { label: "Guinea-Bissau", value: "gw" },
    { label: "Guyana", value: "gy" },
    { label: "Haiti", value: "ht" },
    { label: "Heard Island and Mcdonald Islands", value: "hm" },
    { label: "Holy See (Vatican City State)", value: "va" },
    { label: "Honduras", value: "hn" },
    { label: "Hong Kong", value: "hk" },
    { label: "Hungary", value: "hu" },
    { label: "Iceland", value: "is" },
    { label: "India", value: "in" },
    { label: "Indonesia", value: "id" },
    { label: "Iran, Islamic Republic of", value: "ir" },
    { label: "Iraq", value: "iq" },
    { label: "Ireland", value: "ie" },
    { label: "Isle of Man", value: "im" },
    { label: "Israel", value: "il" },
    { label: "Italy", value: "it" },
    { label: "Jamaica", value: "jm" },
    { label: "Japan", value: "jp" },
    { label: "Jersey", value: "je" },
    { label: "Jordan", value: "jo" },
    { label: "Kazakhstan", value: "kz" },
    { label: "Kenya", value: "ke" },
    { label: "Kiribati", value: "ki" },
    { label: "Korea, Democratic People's Republic of", value: "kp" },
    { label: "Korea, Republic of", value: "kr" },
    { label: "Kuwait", value: "kw" },
    { label: "Kyrgyzstan", value: "kg" },
    { label: "Lao People's Democratic Republic", value: "la" },
    { label: "Latvia", value: "lv" },
    { label: "Lebanon", value: "lb" },
    { label: "Lesotho", value: "ls" },
    { label: "Liberia", value: "lr" },
    { label: "Libyan Arab Jamahiriya", value: "ly" },
    { label: "Liechtenstein", value: "li" },
    { label: "Lithuania", value: "lt" },
    { label: "Luxembourg", value: "lu" },
    { label: "Macao", value: "mo" },
    { label: "Macedonia, The Former Yugoslav Republic of", value: "mk" },
    { label: "Madagascar", value: "mg" },
    { label: "Malawi", value: "mw" },
    { label: "Malaysia", value: "my" },
    { label: "Maldives", value: "mv" },
    { label: "Mali", value: "ml" },
    { label: "Malta", value: "mt" },
    { label: "Marshall Islands", value: "mh" },
    { label: "Martinique", value: "mq" },
    { label: "Mauritania", value: "mr" },
    { label: "Mauritius", value: "mu" },
    { label: "Mayotte", value: "yt" },
    { label: "Mexico", value: "mx" },
    { label: "Micronesia, Federated States of", value: "fm" },
    { label: "Moldova, Republic of", value: "md" },
    { label: "Monaco", value: "mc" },
    { label: "Mongolia", value: "mn" },
    { label: "Montserrat", value: "ms" },
    { label: "Morocco", value: "ma" },
    { label: "Mozambique", value: "mz" },
    { label: "Myanmar", value: "mm" },
    { label: "Namibia", value: "na" },
    { label: "Nauru", value: "nr" },
    { label: "Nepal", value: "np" },
    { label: "Netherlands", value: "nl" },
    { label: "Netherlands Antilles", value: "an" },
    { label: "New Caledonia", value: "nc" },
    { label: "New Zealand", value: "nz" },
    { label: "Nicaragua", value: "ni" },
    { label: "Niger", value: "ne" },
    { label: "Nigeria", value: "ng" },
    { label: "Niue", value: "nu" },
    { label: "Norfolk Island", value: "nf" },
    { label: "Northern Mariana Islands", value: "mp" },
    { label: "Norway", value: "no" },
    { label: "Oman", value: "om" },
    { label: "Pakistan", value: "pk" },
    { label: "Palau", value: "pw" },
    { label: "Palestinian Territory, Occupied", value: "ps" },
    { label: "Panama", value: "pa" },
    { label: "Papua New Guinea", value: "pg" },
    { label: "Paraguay", value: "py" },
    { label: "Peru", value: "pe" },
    { label: "Philippines", value: "ph" },
    { label: "Pitcairn", value: "pn" },
    { label: "Poland", value: "pl" },
    { label: "Portugal", value: "pt" },
    { label: "Puerto Rico", value: "pr" },
    { label: "Qatar", value: "qa" },
    { label: "Reunion", value: "re" },
    { label: "Romania", value: "ro" },
    { label: "Russian Federation", value: "ru" },
    { label: "Rwanda", value: "rw" },
    { label: "Saint Helena", value: "sh" },
    { label: "Saint Kitts and Nevis", value: "kn" },
    { label: "Saint Lucia", value: "lc" },
    { label: "Saint Pierre and Miquelon", value: "pm" },
    { label: "Saint Vincent and the Grenadines", value: "vc" },
    { label: "Samoa", value: "ws" },
    { label: "San Marino", value: "sm" },
    { label: "Sao Tome and Principe", value: "st" },
    { label: "Saudi Arabia", value: "sa" },
    { label: "Senegal", value: "sn" },
    { label: "Serbia and Montenegro", value: "cs" },
    { label: "Seychelles", value: "sc" },
    { label: "Sierra Leone", value: "sl" },
    { label: "Singapore", value: "sg" },
    { label: "Slovakia", value: "sk" },
    { label: "Slovenia", value: "si" },
    { label: "Solomon Islands", value: "sb" },
    { label: "Somalia", value: "so" },
    { label: "South Africa", value: "za" },
  ]
  return { countries }
}


export const FetchedResourcePageData = data => {
  const options = [
    { value: "latest", label: "Latest" },
    { value: "blogs", label: "Blogs" },
    // { value: "platform-tours", label: "Platform Tours" },
    { value: "data-sheets", label: "Data Sheets"},
    { value: "videos", label: "Videos" },
    { value: "webinars", label: "Webinars/ Events" },
  ]
  const style = {
    control: base => ({
      ...base,
      border: "1px solid #000",
      fontFamily: "Helvicta",
      // This line disable the blue border
      boxShadow: "none",
    }),
  }
  const tabs = [
    { id: "latest", label: "Latest" },
    { id: "blogs", label: "Blogs" },
    // { id: "platform-tours", label: "Platform Tours" },
    { id: "data-sheets", label: "Data Sheets" },
    { id: "videos", label: "Videos" },
    { id: "webinars", label: "Webinars/ Events" },
  ]
  return { options, style, tabs }
}


///added by akib


export const YouTubeVideo = (data) => {
  var video = [
    {
    src: "https://www.youtube.com/embed/mqOGVL2rIxo?si:su0MKs46vjlMGx1l?rel=0&amp;fs=0&amp;showinfo=0",
    title: "",
    frameborder: "0",
    allow:"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
    allowfullscreen: "allowfullscreen",
    },
//     // {
//     //   src: "https://www.youtube.com/embed/4JrilMiCW5Y?si=2HU9-9m2h6Uyz-q7?controls=0&showinfo=0&modestbranding=1",
//     //   // title: "YouTube video player",
//     //   frameborder: "0",
//     //   allow:"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
//     //   allowfullscreen: "allowfullscreen",
//     //   },
//     //   {
//     //     src: "https://www.youtube.com/embed/f5EEFk6Znw4?si=r-HTIrbYT2ig7C81?controls=0&showinfo=0&modestbranding=1",
//     //     title: "YouTube video player",
//     //     frameborder: "0",
//     //     allow:"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
//     //     allowfullscreen: "allowfullscreen",
//     //     },
//     //     {
//     //       src: "https://www.youtube.com/embed/yv1bnFYWQag?si=u1KA-QjYNVVhI-_U?controls=0&showinfo=0&modestbranding=1",
//     //       title: "YouTube video player",
//     //       frameborder: "0",
//     //       allow:"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
//     //       allowfullscreen: "allowfullscreen",
//     //       }
          
  ]
  return {video};
}

export const pryzmaiData=data=>{
  const banner=data?.pryzmai?.pryzmai?.journey[0]?.attributes;
  const section1=data?.pryzmai?.pryzmai?.journey[1]?.attributes;
  const section2=data?.pryzmai?.pryzmai?.journey[2]?.attributes;
  const section3=data?.pryzmai?.pryzmai?.journey[3]?.attributes;
  const section4=data?.pryzmai?.pryzmai?.journey[4]?.attributes;
  const section5=data?.pryzmai?.pryzmai?.journey[5]?.attributes;
  const section6=data?.pryzmai?.pryzmai?.journey[6]?.attributes;
  const section7=data?.pryzmai?.pryzmai?.journey[7]?.attributes;
  const section8=data?.pryzmai?.pryzmai?.journey[8]?.attributes;
  const testimonialSlider = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return {banner,section1,section2,section3,section4,section5,section6,section7,section8,testimonialSlider};
}

export const featureData=data=>{
  const banner=data?.feature?.feature?.journey[0]?.attributes;
return {banner};
}

export const platformData=data=>{
  console.log(data,"asdf")
  const banner=data?.platform?.platform?.journey[0]?.attributes;
  const section1=data?.platform?.platform?.journey[1]?.attributes;

return {banner,section1};
}
export const FetchedCompanyPageData = data => {
  let allCompanyPageObj = data
  let companyPageObj = {}
  for (let i = 0; i < allCompanyPageObj.length; i++) {
    let cmpname = allCompanyPageObj[i].attributes.cmpname
    companyPageObj[`${cmpname}` + `-cmpname`] =   cmpname
    companyPageObj[`${cmpname}` + `-header`] =  allCompanyPageObj[i].attributes.headers
    companyPageObj[`${cmpname}` + `-paragraph` ] = allCompanyPageObj[i].attributes.paragraph
    if (allCompanyPageObj[i].attributes.dynamicCmp.length > 1) {
      for ( let j = 0; j < allCompanyPageObj[i].attributes.dynamicCmp.length; j++ ) {
        companyPageObj[`${cmpname}` + `-images${j + 1}`] = allCompanyPageObj[i].attributes.dynamicCmp[j]?.imageUrl
      }
    } else {
      companyPageObj[`${cmpname}` + `-images`] = allCompanyPageObj[i].attributes.dynamicCmp[0]?.imageUrl
    }
  }
  return companyPageObj
}


export const SheetsData=()=>{
  var sheets=[
    {
      imageUrl:"pryzmImg",
      title:"Pryzm Data Sheet",
      pdf:"PRYZM_Datasheet.pdf"
    }
  ]
  return {sheets}
};